<!--
 * @Author: your name
 * @Date: 2021-03-16 13:59:49
 * @LastEditTime: 2021-03-16 14:09:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /hx/packages/table/table.vue
-->
<template>
  <div>
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "Xiaoxiannan",
  data() {
    return {
      name: "小仙男",
    };
  },
};
</script>

<style></style>
